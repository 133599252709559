import { ChangeEvent, useContext, useEffect, useState } from 'react';
import {
  Card,
  Box,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  Typography,
  Tooltip,
  IconButton,
  useTheme,
  styled,
  Button,
  InputAdornment,
  CircularProgress,
  TextField
} from '@mui/material';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import Filter from 'src/content/applications/Users/common/filter';
import CopyAllTwoTone from '@mui/icons-material/CopyAllTwoTone';
import { ManagementServices } from 'src/services/ManagementServices';
import Swal from 'sweetalert2';
import { CategoryDetails } from 'src/models/Category';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './../ToastStyle.css';
import moment from 'moment';
import SearchIcon from '@mui/icons-material/Search';
import { InventoryService } from 'src/services/InventoryService';
import { ShopsDetails } from 'src/models/Shops';
import { TemplateInventoryDetails } from 'src/models/InventoryDetails';
import { Link } from 'react-router-dom';
import EditShop from '../Users/shopProfile/EditShopPopUp';
import ImportCSV from './ImportCSV';
import ImportShopInventoryTemplate from './ImportShopInventoryTemplate';
import { CreateShop } from './create-shop-popup';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import UserDetails from './UserDetails';
import AdminInvoice from './AdminInvoice/AdminInvoice';
import AdminCustomer from './AdminCustomers/AdminCustomer';
import AdminInventory from './AdminInventory/AdminInventory';
import DeletePopUp from './DeletePopUP';
import PaymentStatusBox from './PaymentStatusBox';
import PreviewTwoToneIcon from '@mui/icons-material/PreviewTwoTone';
import EditShopNote from '../Users/shopProfile/EditShopNote';
import UserContext from 'src/context/UserContext';
import { Role } from 'src/Role';
export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

interface Filters {
  search: string;
  status?: any;
}

const applyFilters = (
  TemplateCategories: ShopsDetails[],
  filters: Filters
): ShopsDetails[] => {
  return TemplateCategories.filter((category) => {
    let matches = true;

    if (filters.search) {
      const keyword = filters.search.toLowerCase();
      if (!category.shopName?.toLowerCase().includes(keyword)) {
        matches = false;
      }
    }
    return matches;
  });
};

const applyPagination = (
  allGroups: CategoryDetails[],
  page: number,
  limit: number
): any[] => {
  return allGroups.slice(page * limit, page * limit + limit);
};

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 5 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
const PremiumShopsTable = ({}) => {
  const [billedValue, setBilledValue] = useState<any>('None');
  const [value, setValue] = useState<any>('All');
  const [startValue, setStartValue] = useState(null);
  const [endValue, setEndValue] = useState(null);
  const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedAllGroups, setSelectedAllGroups] = useState<string[]>([]);
  const selectedBulkActions = selectedAllGroups.length > 0;
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [filters, setFilters] = useState<Filters>({ search: null });
  const [allShops, setAllShops] = useState<any[]>([]);
  const [shopId, setId] = useState();
  const [mainTableInventory, setMainTableInventory] = useState<
    TemplateInventoryDetails[]
  >([]);
  const filteredMainTableInventory = applyFilters(mainTableInventory, filters);
  const [openEdit, setOpenEdit] = useState(false);
  const [openCSV, setOpenCSV] = useState(false);
  const [openUserDetails, SetOpenUserDetails] = useState(false);
  const [openeDeletePopUp, setOpenDeletePopUp] = useState(false);
  const [openTemplate, setOpenTemplate] = useState(false);
  const [selectedShopId, setSelectedShopId] = useState();
  const [selectedShopIdD, setSelectedShopIdD] = useState();
  const [selectedShopIndexD, setSelectedShopIndexD] = useState();
  const [selectedShopUserId, setSelectedShopUserId] = useState();
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [currentSearchTerm, setCurrentSearchTerm] = useState('');
  const [AllCategories, setAllCategories] = useState([] as CategoryDetails[]);
  const [loading, setLoading] = useState(false);
  const [ifSearchChanged, setIfSearchChanged] = useState(false);
  const [currentPageComponents, setCurrentPageComponents] = useState<number>(0);
  const [editIndex, setEditIndex] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [openNoteEdit, setOpenNoteEdit] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedShopNote, setSelectedShopNote] = useState<any[]>([]);
  const [user] = useContext(UserContext);

  useEffect(() => {
    getAllShops();
    setCurrentDate(new Date());
  }, [currentSearchTerm, page, limit, startValue, endValue]);

  const getAllShops = async () => {
    try {
      const params = new URLSearchParams({
        search: currentSearchTerm,
        startDate: startValue,
        endDate: endValue,
        page: (page + 1).toString(),
        limit: limit.toString()
      });

      const res = await InventoryService.getAllPremiumShops(params.toString());
      if (res.success) {
        if (ifSearchChanged) {
          const shops = res.data.shops || [];
          setAllShops(shops);
        } else {
          const shops = res.data.shops || [];
          setAllShops((prevList) => [...prevList, ...shops]);
        }
        setIfSearchChanged(false);
        setLoading(false);
      } else {
        toast.error('Failed to fetch shops');
      }
    } catch (error) {
      console.error('Error fetching shops:', error);
      toast.error('An error occurred while fetching shops');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setLoading(true);
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
    setPage(0);
  };

  const filteredAllGroups = applyFilters(allShops, filters);
  const paginatedAllGroups = applyPagination(filteredAllGroups, page, limit);
  const selectedSomeCryptoOrders =
    selectedAllGroups.length > 0 &&
    selectedAllGroups.length < AllCategories.length;
  const selectedAllCryptoOrders =
    selectedAllGroups.length === AllCategories.length;
  const theme = useTheme();

  const handleEditOpen = (index: any, shopID: any, userID: any) => {
    setSelectedShopId(shopID);
    setSelectedShopUserId(userID);
    setOpenEdit(true);
    setEditIndex(index);
  };
  const handleNoteEditOpen = (index: any, shop: any) => {
    setSelectedShopId(shop?._id);
    setSelectedShopUserId(shop?.createdBy);
    setSelectedShopNote(shop?.shopNote);
    setOpenNoteEdit(true);
    setEditIndex(index);
  };
  const handleNoteEditClose = () => {
    setOpenNoteEdit(false);
  };
  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const handleCSVOpen = (shopID: any) => {
    setSelectedShopId(shopID);
    setOpenCSV(true);
  };

  const handleCSVClose = () => {
    setOpenCSV(false);
  };

  const handleUserDetailsOpen = (shopID: any) => {
    setSelectedShopId(shopID);
    SetOpenUserDetails(true);
  };

  const handleUserDetailsClose = () => {
    SetOpenUserDetails(false);
  };

  const handleTemplateOpen = (shopID: any, index: any) => {
    setSelectedShopId(shopID);
    setOpenTemplate(true);
    setEditIndex(index);
  };

  const handleTemplateClose = () => {
    setOpenTemplate(false);
  };

  const handleSearchChange = () => {
    setOpen(false);
    setIfSearchChanged(true);
    setPage(0);
  };

  const handleSearch = () => {
    setIsLoading(true);
    setCurrentSearchTerm(searchKeyword);
    setIfSearchChanged(true);
    setPage(0);
  };
  const handleSearchClear = () => {
    setIsLoading(true);
    setIfSearchChanged(true);
    setSearchKeyword('');
    setCurrentSearchTerm('');
    setPage(0);
  };

  const handleDeletePopUpOpen = (shopID: any, index: any) => {
    setSelectedShopIdD(shopID);
    setSelectedShopIndexD(index);
    setOpenDeletePopUp(true);
  };

  const handleDeletePopUpClose = (index: any) => {
    setOpenDeletePopUp(false);
  };

  const handleCopy = async (shopId) => {
    await navigator.clipboard.writeText(shopId);
    toast('Copied to clipboard', {
      style: {
        boxShadow: 'none'
      }
    });
  };

  const deleteConform = (deleteOption: string) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      cancelButtonText: 'Close'
    }).then((result) => {
      if (result.isConfirmed) {
        ManagementServices.deleteShop(selectedShopIdD, deleteOption)
        .then((res) => {
          Swal.fire('Deleted!', 'Shop has been deleted.', 'success').then(
            (result) => {
              if (result.isConfirmed) {
                const deleteShop = [...allShops];
                deleteShop.splice(selectedShopIndexD, 1);
                setAllShops(deleteShop);
              }
            }
          );
        })
        .catch((error) => {
          Swal.fire(
            'Error!',
            'There was an error deleting your file.',
            'error'
          );
        });
      }
    });
  };

  const handelBilledAmout = (value: any) => {
    setBilledValue(value);
    setIfSearchChanged(true);
    setPage(0);
  };
  const handleChange = (event: any) => {
    const selectedvalue = event.target.value;
    const today = new Date();
    setValue(selectedvalue);
    setOpen(false);
    setIfSearchChanged(true);
    setPage(0);
    switch (selectedvalue) {
      case 'Today':
        const todayDateBefore = new Date(today);
        todayDateBefore.setDate(today.getDate() - 1);
        setStartValue(todayDateBefore);
        setEndValue(new Date());
        setShowCustomDatePicker(false);

        break;
      case 'Last 7 days':
        const sevenDaysAgo = new Date(today);
        sevenDaysAgo.setDate(today.getDate() - 6);
        setEndValue(today);
        setStartValue(sevenDaysAgo);
        setShowCustomDatePicker(false);

        break;
      case 'Last 28 days':
        const twentyEightDaysAgo = new Date(today);
        twentyEightDaysAgo.setDate(today.getDate() - 27);
        setEndValue(today);
        setStartValue(twentyEightDaysAgo);
        setShowCustomDatePicker(false);

        break;
      case 'Custom':
        setStartValue(null);
        setEndValue(null);
        setShowCustomDatePicker(true);

        break;
      default:
        setStartValue(null);
        setEndValue(null);
        setShowCustomDatePicker(false);

        break;
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const navigateToProductsPage = (shopId: any) => {
    if (shopId != undefined) {
      setId(shopId);
      setCurrentPageComponents(1);
    }
  };

  const callBackPage = () => {
    setCurrentPageComponents(0);
  };

  const navigateToCustomersPage = (shopId: any) => {
    if (shopId != undefined) {
      setId(shopId);
      setCurrentPageComponents(2);
    }
  };

  const callBackPageCustomer = () => {
    setCurrentPageComponents(0);
  };

  const navigateToInventoryPage = (shopId: any) => {
    if (shopId != undefined) {
      setId(shopId);
      setCurrentPageComponents(3);
    }
  };

  const callBackPageInventory = () => {
    setCurrentPageComponents(0);
  };
  const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
      width: '50vw',
      maxWidth: 'none'
    }
  }));
  const handlePreviewOpen = (shop: any) => {
    setOpenPreview(true);
    setSelectedShopNote(shop?.shopNote);
  };
  const handlePreviewClose = () => {
    setOpenPreview(false);
  };

  const handleCheckboxChange = async (shop: any, index: any) => {
    try {
      if (user?.role != Role.CUSTOMER_SUPPORT) {
        const id = shop?._id;
        const data = {
          activeStatus: !shop?.activeStatus
        };
        InventoryService.updateShop(data, id).then((res) => {
          if (res.success) {
            const updatedactiveStatus = data.activeStatus;
            const updatedTemplageProduct = [...allShops];
            updatedTemplageProduct[index] = {
              ...updatedTemplageProduct[index],
              activeStatus: updatedactiveStatus
            };
            setAllShops(updatedTemplageProduct);
            Swal.fire('Shop Status Changed', 'Successfully', 'success');
          } else {
            Swal.fire({
              icon: 'error',
              title: res.error,
              confirmButtonColor: '#FD7F00'
            });
          }
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: error,
        confirmButtonColor: '#FD7F00'
      });
    }
  };
  const handlePaymentCheckboxChange = async (shop: any, index: any) => {
    try {
      if (user?.role != Role.CUSTOMER_SUPPORT) {
        const id = shop?._id;
        const data = {
          activePaymentStatus: !shop?.activePaymentStatus
        };
        InventoryService.updateShop(data, id).then((res) => {
          if (res.success) {
            const updatedactiveStatus = data.activePaymentStatus;
            const updatedTemplageProduct = [...allShops];
            updatedTemplageProduct[index] = {
              ...updatedTemplageProduct[index],
              activePaymentStatus: updatedactiveStatus
            };
            setAllShops(updatedTemplageProduct);
            Swal.fire('Shop Status Changed', 'Successfully', 'success');
          } else {
            Swal.fire({
              icon: 'error',
              title: res.error,
              confirmButtonColor: '#FD7F00'
            });
          }
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: error,
        confirmButtonColor: '#FD7F00'
      });
    }
  };
  return (
    <>
      <StyledDialog open={openPreview} onClose={handlePreviewClose}>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handlePreviewClose}
        >
          <Typography variant="h4" fontWeight="bold">
            Shop Note
          </Typography>
        </BootstrapDialogTitle>
        <>
          <Typography
            sx={{
              display: 'inline',
              marginTop: '-20px',
              marginBottom: '10px',
              marginLeft: '50px'
            }}
            component="span"
            variant="body2"
            color="text.primary"
          >
            {selectedShopNote}
          </Typography>
        </>
        <DialogContent></DialogContent>
      </StyledDialog>
      {currentPageComponents == 0 && (
        <Card>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{ flexWrap: 'wrap', mb: 1 }}
            alignItems="center"
            gap={1}
          >
            <Typography
              variant="h4"
              component="h2"
              m={2}
              sx={{
                fontSize: '2rem',
                fontWeight: 'bold'
              }}
            >
              Premium Shops
            </Typography>
            <Box
              display="flex"
              sx={{ flexWrap: 'wrap', ml: 2, mr: 2 }}
              alignItems="center"
              gap={1}
            >
              {user?.role != Role.CUSTOMER_SUPPORT && (
                <CreateShop
                  AllCategories={AllCategories}
                  setAllCategories={setAllCategories}
                  allShops={allShops}
                  setAllShops={setAllShops}
                />
              )}

              <Filter
                billedValue={billedValue}
                handelBilledAmout={handelBilledAmout}
                value={value}
                startValue={startValue}
                endValue={endValue}
                showCustomDatePicker={showCustomDatePicker}
                handleChange={handleChange}
                handleOpen={handleOpen}
                setStartValue={setStartValue}
                setEndValue={setEndValue}
                handleSearchChange={handleSearchChange}
                dropDownHeading="Billed Amount"
                open={open}
                maxDate={currentDate}
              />

              <TextField
                variant="outlined"
                placeholder="Search Shops"
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleSearch()}
                        color="primary"
                      >
                        <SearchIcon />
                      </IconButton>
                      <IconButton onClick={handleSearchClear} color="error">
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
          </Box>

          <Divider />
          {isLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="300px"
            >
              <CircularProgress />
            </Box>
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ShopId</TableCell>
                    <TableCell>Active</TableCell>
                    <TableCell>Shop Name</TableCell>
                    <TableCell>User Email / Contact Number </TableCell>
                    <TableCell>Shop Contact Number</TableCell>
                    <TableCell>Valid Days</TableCell>
                    <TableCell>Period</TableCell>
                    <TableCell>payment Type</TableCell>
                    <TableCell>payment Status</TableCell>
                    <TableCell>Created Date</TableCell>
                    <TableCell>Invoice</TableCell>
                    <TableCell>Note</TableCell>
                    {user?.role != Role.CUSTOMER_SUPPORT && (
                      <TableCell align="center">Actions</TableCell>
                    )}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {allShops.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={12} align="center">
                        <Typography
                          variant="h4"
                          component="h2"
                          sx={{
                            fontSize: '1rem',
                            fontWeight: 'bold'
                          }}
                        >
                          No data to display
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    allShops?.map((shop, index) => {
                     const start = moment();
                     const end = moment(shop.paymentEndDate, 'DD/MM/YYYY');
                     const startDate = moment(
                       shop.paymentStartDate,
                       'DD/MM/YYYY'
                     );
                     const totalDays = end.diff(start, 'days');
                     const days = end.diff(startDate, 'days');
                      
                      return (
                        <TableRow hover key={index}>
                          <TableCell>
                            {index + 1}
                            <Tooltip title="Copy ID" arrow>
                              <IconButton
                                onClick={() => handleCopy(shop._id)}
                                sx={{
                                  '&:hover': {
                                    background: theme.colors.primary.lighter
                                  },
                                  color: theme.palette.warning.main
                                }}
                                color="inherit"
                                size="small"
                              >
                                <CopyAllTwoTone fontSize="small" />
                                <ToastContainer
                                  position="bottom-right"
                                  autoClose={1000}
                                  hideProgressBar
                                  newestOnTop={false}
                                  closeOnClick={false}
                                  rtl={false}
                                  pauseOnFocusLoss={false}
                                  draggable={false}
                                  pauseOnHover={false}
                                  theme="dark"
                                />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <Checkbox
                              checked={shop?.activeStatus || false}
                              onChange={() => handleCheckboxChange(shop, index)}
                            />
                          </TableCell>
                          {user?.role != Role.CUSTOMER_SUPPORT ? (
                            <>
                              <TableCell>
                                <Link
                                  to={`/management/profile/shop-profile?shopId=${shop._id}`}
                                >
                                  <Typography
                                    component="span"
                                    sx={{
                                      cursor: 'pointer',
                                      textDecoration: 'underline',
                                      color: 'primary.main'
                                    }}
                                  >
                                    {shop?.shopName}
                                  </Typography>
                                </Link>
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell>{shop?.shopName}</TableCell>
                            </>
                          )}
                          {user?.role != Role.CUSTOMER_SUPPORT ? (
                            <>
                              <TableCell>
                                <Typography
                                  component="span"
                                  onClick={() =>
                                    handleUserDetailsOpen(shop?._id)
                                  }
                                  sx={{
                                    cursor:
                                      shop?.userEmail || shop?.userPhoneNumber
                                        ? 'pointer'
                                        : 'default',
                                    textDecoration:
                                      shop?.userEmail || shop?.userPhoneNumber
                                        ? 'underline'
                                        : 'none',
                                    color:
                                      shop?.userEmail || shop?.userPhoneNumber
                                        ? 'primary.main'
                                        : 'text.primary'
                                  }}
                                >
                                  {shop?.userEmail
                                    ? shop?.userEmail
                                    : shop?.userPhoneNumber}
                                </Typography>
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell>
                                {shop?.userEmail
                                  ? shop?.userEmail
                                  : shop?.userPhoneNumber}
                              </TableCell>
                            </>
                          )}
                          {user?.role != Role.CUSTOMER_SUPPORT ? (
                            <>
                              <TableCell>
                                <Typography
                                  component="span"
                                  onClick={() =>
                                    handleUserDetailsOpen(shop?._id)
                                  }
                                  sx={{
                                    cursor: shop?.phoneNumber
                                      ? 'pointer'
                                      : 'default',
                                    textDecoration: shop?.phoneNumber
                                      ? 'underline'
                                      : 'none',
                                    color: shop?.phoneNumber
                                      ? 'primary.main'
                                      : 'text.primary'
                                  }}
                                >
                                  {shop?.phoneNumber ? shop?.phoneNumber : '-'}
                                </Typography>
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell>{shop?.phoneNumber}</TableCell>
                            </>
                          )}

                          <TableCell>{totalDays}</TableCell>
                          <TableCell>
                            {shop?.paymentStartDate} {' - '}{' '}
                            {shop?.paymentEndDate}
                          </TableCell>
                          {/* <TableCell>{shop?.paymentType?.length>0 ? shop?.paymentType[shop?.paymentType?.length - 1] : "N/A"}</TableCell> */}
                          <TableCell>
                            {shop?.paymentType ? shop?.paymentType : 'N/A'}
                          </TableCell>
                          <TableCell>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              <PaymentStatusBox
                                status={
                                  shop?.paymentStatus
                                    ? shop?.paymentStatus == 'PAID'
                                      ? days > 364
                                        ? 'yearly'
                                        : 'monthly'
                                      : shop?.paymentStatus
                                    : 'N/A'
                                }
                              />
                             
                              <Checkbox
                                icon={<FavoriteBorder  />}
                                checkedIcon={<Favorite sx={{ color: '#e8b923' }} />}
                                checked={shop?.activePaymentStatus || false}
                                onChange={() =>
                                  handlePaymentCheckboxChange(shop, index)
                                }
                                sx={{
                                  '&.Mui-checked': {
                                    color: '#e8b923' 
                                  }
                                }}
                              />
                            </Box>
                          </TableCell>
                          <TableCell>
                            {moment(shop?.createdAt).format(
                              'MMM Do YYYY, h:mm A'
                            )}
                          </TableCell>
                          {/* {user?.role != Role.CUSTOMER_SUPPORT ? (
                            <>
                              <TableCell>
                                <Button
                                  onClick={() => {
                                    navigateToCustomersPage(shop._id);
                                  }}
                                  variant="outlined"
                                  color="primary"
                                  sx={{
                                    width: '40px',
                                    minWidth: '40px',
                                    borderColor: 'primary.main',
                                    color: 'primary.main',
                                    '&:hover': {
                                      borderColor: 'primary.dark',
                                      color: 'primary.dark'
                                    },
                                    padding: '1px 0px',
                                    mb: 0
                                  }}
                                >
                                  {shop.customerCount ?? 'Loading...'}
                                </Button>
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell>{shop?.customerCount}</TableCell>
                            </>
                          )}
                          {user?.role != Role.CUSTOMER_SUPPORT ? (
                            <>
                              <TableCell>
                                <Button
                                  onClick={() => {
                                    navigateToInventoryPage(shop._id);
                                  }}
                                  variant="outlined"
                                  color="primary"
                                  sx={{
                                    width: '40px',
                                    minWidth: '40px',
                                    borderColor: 'primary.main',
                                    color: 'primary.main',
                                    '&:hover': {
                                      borderColor: 'primary.dark',
                                      color: 'primary.dark'
                                    },
                                    padding: '1px 0px',
                                    mb: 0
                                  }}
                                >
                                  {shop.inventoryCount ?? 'Loading...'}
                                </Button>
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell>{shop?.inventoryCount}</TableCell>
                            </>
                          )} */}
                          {user?.role != Role.CUSTOMER_SUPPORT ? (
                            <>
                              <TableCell>
                                <Button
                                  onClick={() => {
                                    navigateToProductsPage(shop._id);
                                  }}
                                  variant="outlined"
                                  color="primary"
                                  sx={{
                                    width: '40px',
                                    minWidth: '40px',
                                    borderColor: 'primary.main',
                                    color: 'primary.main',
                                    '&:hover': {
                                      borderColor: 'primary.dark',
                                      color: 'primary.dark'
                                    },
                                    padding: '1px 0px',
                                    mb: 0
                                  }}
                                >
                                  {shop.invoiceCount ?? 'Loading...'}
                                </Button>
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell>{shop?.invoiceCount}</TableCell>
                            </>
                          )}

                          <TableCell align="center">
                            <Tooltip title="Edit shop Note" arrow>
                              <IconButton
                                onClick={() => handleNoteEditOpen(index, shop)}
                                sx={{
                                  '&:hover': {
                                    background: theme.colors.primary.lighter
                                  },
                                  color: theme.palette.primary.main
                                }}
                                color="inherit"
                                size="small"
                              >
                                <EditTwoToneIcon
                                  fontSize="small"
                                  color="inherit"
                                />
                              </IconButton>
                            </Tooltip>
                            {shop?.shopNote && (
                              <Tooltip title="View Shop Note" arrow>
                                <IconButton
                                  onClick={() => handlePreviewOpen(shop)}
                                  sx={{
                                    '&:hover': {
                                      background: theme.colors.primary.lighter
                                    },
                                    color: theme.palette.primary.main
                                  }}
                                  color="inherit"
                                  size="small"
                                >
                                  <PreviewTwoToneIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </TableCell>
                          {user?.role != Role.CUSTOMER_SUPPORT && (
                            <TableCell align="center">
                              <Tooltip title="Edit shop details" arrow>
                                <IconButton
                                  onClick={() =>
                                    handleEditOpen(
                                      index,
                                      shop._id,
                                      shop.createdBy
                                    )
                                  }
                                  sx={{
                                    '&:hover': {
                                      background: theme.colors.primary.lighter
                                    },
                                    color: theme.palette.primary.main
                                  }}
                                  color="inherit"
                                  size="small"
                                >
                                  <EditTwoToneIcon
                                    fontSize="small"
                                    color="inherit"
                                  />
                                </IconButton>
                              </Tooltip>

                              {/* <Tooltip title="Import inventory for shop" arrow>
                                <IconButton
                                  onClick={() => {
                                    handleCSVOpen(shop._id);
                                  }}
                                  sx={{
                                    '&:hover': {
                                      background: theme.colors.primary.lighter
                                    },
                                    color: theme.palette.primary.main
                                  }}
                                  color="inherit"
                                  size="small"
                                >
                                  <UploadIcon
                                    fontSize="small"
                                    color="success"
                                  />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Add inventory for shop" arrow>
                                <IconButton
                                  onClick={() => {
                                    handleTemplateOpen(shop._id, index);
                                  }}
                                  sx={{
                                    '&:hover': {
                                      background: theme.colors.primary.lighter
                                    },
                                    color: theme.palette.primary.main
                                  }}
                                  color="inherit"
                                  size="small"
                                >
                                  <AddCircleIcon
                                    fontSize="small"
                                    color="action"
                                  />
                                </IconButton>
                              </Tooltip> */}

                              <Tooltip title="Delete Shop" arrow>
                                <IconButton
                                  onClick={() =>
                                    handleDeletePopUpOpen(shop._id, index)
                                  }
                                  sx={{
                                    '&:hover': {
                                      background: theme.colors.primary.lighter
                                    },
                                    color: theme.palette.primary.main
                                  }}
                                  color="inherit"
                                  size="small"
                                >
                                  <DeleteTwoToneIcon
                                    fontSize="small"
                                    color="error"
                                  />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {allShops.length === 0 ? (
            <Typography></Typography>
          ) : (
            <Box p={2} display="flex" justifyContent="center">
              {allShops.length >= 10 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => handlePageChange(e, page + 1)}
                >
                  {loading ? 'Loading...' : 'See More'}
                </Button>
              )}
            </Box>
          )}

          <EditShop
            open={openEdit}
            onClose={handleEditClose}
            shopId={selectedShopId}
            userId={selectedShopUserId}
            allShops={allShops}
            setAllShops={setAllShops}
            index={editIndex}
          />
          <EditShopNote
            open={openNoteEdit}
            selectedShopNote={selectedShopNote}
            onClose={handleNoteEditClose}
            shopId={selectedShopId}
            userId={selectedShopUserId}
            allShops={allShops}
            setAllShops={setAllShops}
            index={editIndex}
          />
          <ImportCSV
            open={openCSV}
            onClose={handleCSVClose}
            shopId={selectedShopId}
            get={getAllShops}
          />
          <ImportShopInventoryTemplate
            open={openTemplate}
            onClose={handleTemplateClose}
            shopId={selectedShopId}
            allShops={allShops}
            setAllShops={setAllShops}
            index={editIndex}
          />
          <UserDetails
            open={openUserDetails}
            onClose={handleUserDetailsClose}
            shopId={selectedShopId}
          />
          <DeletePopUp
            open={openeDeletePopUp}
            onClose={handleDeletePopUpClose}
            shopId={selectedShopIdD}
            index={selectedShopIndexD}
            get={deleteConform}
          />
        </Card>
      )}
      {currentPageComponents == 1 && (
        <AdminInvoice callBackPage={() => callBackPage()} shopId={shopId} />
      )}
      {currentPageComponents == 2 && (
        <AdminCustomer
          callBackPage={() => callBackPageCustomer()}
          shopId={shopId}
        />
      )}
      {currentPageComponents == 3 && (
        <AdminInventory
          callBackPage={() => callBackPageInventory()}
          shopId={shopId}
        />
      )}
    </>
  );
};

export default PremiumShopsTable;
