import axios from "axios";
import { Util } from "src/Util";
import { InventoryDetails } from "src/models/Inventory";
import { InventoryCategories } from "src/models/InventoryCategory";
import { TemplateInventoryDetails } from "src/models/InventoryDetails";
import { AppResponse } from "src/models/Response";
import { ShopsDetails } from "src/models/Shops";
import { TemplateCategoryDetails } from "src/models/TemplateCategory";

export class InventoryService {

  public static async getAllTemplateCategories(
    limit?: number,
    offset?: number
  ): Promise<AppResponse<TemplateCategoryDetails[]>> {
    const url = Util.apiAuthUrl(`getAllTemplateCategories`);
    const res = await axios.get<void, AppResponse<TemplateCategoryDetails[]>>(url);
    return res;
  }


  //Get default inventory
  // public static async getDefaultInventory(

  // ): Promise<AppResponse<TemplateInventoryDetails[]>> {
  //   const url = Util.apiAuthUrl(`fetch/default-inventory`);
  //   const res = await axios.get<void, AppResponse<TemplateInventoryDetails[]>>(url);
  //   return res;
  // }

  public static async getDefaultInventory(search: string = '', page: string = '', limit: number = 20): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`fetch/default-inventory?search=${search}&page=${page}&limit=${limit}`);
    const res = await axios.get<void, AppResponse<any>>(url);
    return res;
  }

  public static async getDefaultInventoryProducts(search: any, page: string = '', limit: number = 20, id: string = ''): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`inventory-products/${id}/${limit}/${page}`);
    const res = await axios.post<Partial<any>, AppResponse<any[]>>(url, search);
    return res;
  }



  public static async getinventory(

  ): Promise<AppResponse<TemplateInventoryDetails[]>> {
    const url = Util.apiAuthUrl(`fetch/template-inventory-admin`);
    const res = await axios.get<void, AppResponse<TemplateInventoryDetails[]>>(url);
    return res;
  }

  //get inventory categories
  public static async getinventoryCategories(
    shopId: string,
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`allInventoryCategoriesByShop-admin/` + shopId);
    try {
      const res = await axios.get<void, AppResponse<any>>(url, {
        headers: {
          'Cache-Control': 'no-cache', // Ensures fresh data is fetched
        },
      });
      return res;
    } catch (error) {
      throw error;
    }
  }


  public static async importInventoryTemplate(shopId: any, inventoryTemplateId: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("import-inventory-admin");
    return axios.post<void, AppResponse<any>>(url, { shopId, inventoryTemplateId });
  }




  //Update inventory for admin panel
  public static async updateInventory(
    data: any, id: string
  ): Promise<AppResponse<TemplateInventoryDetails[]>> {
    const url = Util.apiAuthUrl(`fetch/update-inventory/` + id);
    const res = await axios.post<void, AppResponse<TemplateInventoryDetails[]>>(
      url,
      data
    );
    return res;
  }

  //Delete inventory for admin
  public static async deleteInventory(
    id: any
  ): Promise<AppResponse<TemplateInventoryDetails[]>> {
    const url = Util.apiAuthUrl(`delete-inventory/${id}`);
    const res = await axios.delete<void, AppResponse<TemplateInventoryDetails[]>>(url);
    return res;
  }


  //Create Inventory
  public static async createInventory(
    data: any
  ): Promise<AppResponse<TemplateInventoryDetails>> {
    try {
      const url = Util.apiAuthUrl(`create-inventory-admin`); 
      const res = await axios.post<void, AppResponse<TemplateInventoryDetails>>(url, data);
      return res;
    } catch (error) {
      console.error("Error creating inventory:", error);
      throw error; 
    }
  }


  //Create multiple template inventories for CSV
  public static async createMultipleInventories(
    inventories: any
  ): Promise<AppResponse<TemplateInventoryDetails>> {
    try {
      const url = Util.apiAuthUrl(`create-multiple-inventories`);
      const res = await axios.post<void, AppResponse<TemplateInventoryDetails>>(url, inventories);
      // const response = await axios.post(`${API_URL}/create-multiple`, inventories);
      return res;
    } catch (error) {
      console.error('Error creating multiple inventories:', error);
      throw error;
    }
  };


  //get All Shops for  admin
  public static async getAllShops(
    queryString: string
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`all-shops?${queryString}`);
    const res = await axios.get<void, AppResponse<any>>(url);
    return res;
  }

  public static async getAllShopsLatest(
    queryString: string
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`all-latest-shops?${queryString}`);
    const res = await axios.get<void, AppResponse<any>>(url);
    return res;
  }
  
  public static async getAllPremiumShops(
    queryString: string
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`all-premium-shops?${queryString}`);
    const res = await axios.get<void, AppResponse<any>>(url);
    return res;
  }
  
  public static async getAllActivePremiumShops(
    queryString: string
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`all-active-premium-shops?${queryString}`);
    const res = await axios.get<void, AppResponse<any>>(url);
    return res;
  }
  // //get All Shops for  admin
  // public static async getAllShops(
  //     search: string = ''
  // ): Promise<AppResponse<ShopsDetails[]>> {
  //   const url = Util.apiAuthUrl(`allshops?search=${search}`);
  //   const res = await axios.get<void, AppResponse<ShopsDetails[]>>(url);
  //   return res;
  // }



  //Create shop for admin
  public static async createShops(
    data: any
  ): Promise<AppResponse<ShopsDetails>> {
    try {
      const url = Util.apiAuthUrl(`create-shop-admin`); // Ensure the endpoint matches the backend route
      const res = await axios.post<void, AppResponse<ShopsDetails>>(url, data);
      return res;
    } catch (error) {
      console.error("Error creating inventory:", error);
      throw error; // Handle or throw the error as needed
    }
  }


  //Update Shop for admin
  public static async updateShop(
    data: any, id: string
  ): Promise<AppResponse<ShopsDetails[]>> {
    const url = Util.apiAuthUrl(`update-inventory-by-admin/` + id);
    const res = await axios.post<void, AppResponse<ShopsDetails[]>>(
      url,
      data
    );
    return res;
  }

  public static async updateSubscriptionPlan(
    data: any
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`createBankPayment`);
    const res = await axios.post<void, AppResponse<any>>(
      url,
      data
    );
    return res;
  }

  // Create multiple inventories for shop from CSV
  public static async createMultipleInventoriesForShop(
    inventories: any,
    shopId : any
  ): Promise<AppResponse<any>> {
    try {
      const url = Util.apiAuthUrl(`create-inventories-for-shop/${shopId}`);
      const res = await axios.post<any, AppResponse<any>>(url,  inventories );
      // const response = await axios.post(`${API_URL}/create-multiple`, inventories);
      return res;
    } catch (error) {
      console.error('Error creating multiple inventories:', error);
      throw error;
    }
  };



  //get all inventories by shop
  public static async getAllInventories(
    shopId: any
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`allInventoriesByShop-admin/${shopId}`);
    const res = await axios.get<void, AppResponse<any>>(url);
    return res;
  }

  public static async getAllInventoriesBySearch(
    shopId: any, limit: any, searchData: any, offset = 1
  ): Promise<AppResponse<any[]>> {
    const url = Util.apiAuthUrl(`allInventoriesByShop-admin/search/${shopId}/${limit}/${offset}`);
    const res = await axios.post<Partial<any>, AppResponse<any[]>>(url, searchData);
    return res;
  }

  public static async createTemplateCategory(
    data: any
  ): Promise<AppResponse<TemplateCategoryDetails>> {
    const url = Util.apiAuthUrl(`createTemplateCategory`);
    const res = await axios.post<void, AppResponse<TemplateCategoryDetails>>(url, data);
    return res;
  }

  public static async uploadJsonFile(data: any, templateCategoryId: string): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl(`updateJsonFile/${templateCategoryId}`);
    const res = await axios.post<any, AppResponse<any>>(ep, data, { headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data', } });
    return res;
  }

  //Update product Countity and price
  public static async updateProductQP(
    data: any, id: string
  ): Promise<AppResponse<any[]>> {
    const url = Util.apiAuthUrl(`update-inventory-product-admin/` + id);
    const res = await axios.post<void, AppResponse<any[]>>(
      url,
      data
    );
    return res;
  }

  //Update Template product Countity and price
  public static async updateTemplateProductQP(
    data: any, id: string
  ): Promise<AppResponse<any[]>> {
    const url = Util.apiAuthUrl(`update-inventory-product-from-template-admin/` + id);
    const res = await axios.post<void, AppResponse<any[]>>(
      url,
      data
    );
    return res;
  }

  //get All Invoice for  admin
  // public static async getAllInvoice(
  //   search: string = ''
  // ): Promise<AppResponse<any>> {
  //   const url = Util.apiAuthUrl(`allInvoice-admin?search=${search}`);
  //   const res = await axios.get<void, AppResponse<any>>(url);
  //   return res;
  // }

  public static async getAllInvoice(queryString: string): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`all-invoice-admin?${queryString}`);
    const res = await axios.get<void, AppResponse<any>>(url);
    return res;
  }



  //Update user for admin
  public static async updateUserC(
    data: any, id: string
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`update-user/` + id);
    const res = await axios.post<void, AppResponse<any>>(
      url,
      data
    );
    return res;
  }

  public static async addProduct(data: InventoryDetails): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl('add-products');
    const res = await axios.post<void, AppResponse<any>>(url, data);
    return res;
  }

  public static async getAllCategories(): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl('get-all-inventory-categories-admin');
    const res = await axios.get<void, AppResponse<any>>(url);
    return res;
  }

  public static async createInventoryCategory(data: InventoryCategories): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl('create-inventory-category-admin');
    const res = await axios.post<void, AppResponse<any>>(url, data);
    return res;
  }

  public static async getCategories(
    shopId: string,
    queryString: string
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`get-all-categories-admin/${shopId}?${queryString}`);
   
    try {
      const res = await axios.get<void, AppResponse<any>>(url);
      if (res) {
        console.log("Response received:", res);
      } else {
        console.log("No response received.");
      }
      return res;
    } catch (error) {
      console.error("Error occurred while fetching categories:", error);
      throw error;
    }
  }
  


public static async CreateCustomers(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl('add-customers-by-admin');
    const res = await axios.post<void, AppResponse<any>>(url,data);
    return res;
  }

  //Update Customer for admin
public static async updateCustomer(
  data: any, id :string
): Promise<AppResponse<any>> {
  const url = Util.apiAuthUrl(`update-customer-by-admin/`+id);
  const res = await axios.post<void, AppResponse<any>>(
    url,
    data
  );
  return res;
}

  //Update Invoice for admin
  public static async updateInvoiceByUser(
    data: any, id :string
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`update-invoice-by-admin/`+id);
    const res = await axios.post<void, AppResponse<any>>(
      url,
      data
    );
    return res;
  }

//get Customer by id
public static async getCustomerById(customerId: any): Promise<AppResponse<ShopsDetails>> {
  const ep = Util.apiAuthUrl(`get-customer-by-id-admin/${customerId}`);
  const res = await axios.get<void, AppResponse<ShopsDetails>>(ep);
  return res;
}

public static async deleteCustomer(
  customerId: any
): Promise<AppResponse<any>> {
  const url = Util.apiAuthUrl(`delete-customer-by-admin/${customerId}`);
  const res = await axios.delete<void, AppResponse<any>>(url);
  return res;
}

public static async deleteCategory(
  categoryId: any
): Promise<AppResponse<any>> {
  const url = Util.apiAuthUrl(`delete-category-by-admin/${categoryId}`);
  const res = await axios.delete<void, AppResponse<any>>(url);
  return res;
}

public static async getCategoryById(categoryId: any): Promise<AppResponse<any>> {
  const ep = Util.apiAuthUrl(`get-category-by-id-admin/${categoryId}`);
  const res = await axios.get<void, AppResponse<any>>(ep);
  return res;
}

public static async updateCategory(
  data: any, id :string
): Promise<AppResponse<any>> {
  const url = Util.apiAuthUrl(`update-category-by-admin/`+id);
  const res = await axios.post<void, AppResponse<any>>(
    url,
    data
  );
  return res;
}

// public static async getAllInventoriesBySearch(
//   shopId: any , limit:any ,searchData:any ,offset = 1 
// ): Promise<AppResponse<any[]>> {
//   const url = Util.apiAuthUrl(`allInventoriesByShop-admin/search/${shopId}/${limit}/${offset}`);
//   const res = await axios.post<Partial<any>, AppResponse<any[]>>(url, searchData);
//   return res;
//   }

//delete inventory Template
  public static async deleteInventoryTemplate(
    templateInventoryId: any
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`delete-template-inventory/${templateInventoryId}`);
    const res = await axios.delete<void, AppResponse<any>>(url);
    return res;
  }

  public static async getTemplateById(templateId: any): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl(`get-template-by-id-admin/${templateId}`);
    const res = await axios.get<void, AppResponse<any>>(ep);
    return res;
  }

  public static async updateTemplate(
    data: any, id :string
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`update-template-by-admin/`+id);
    const res = await axios.post<void, AppResponse<any>>(
      url,
      data
    );
    return res;
  }

  public static async deleteTemplateProduct(
    templateProductId: any
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`delete-template-product/${templateProductId}`);
    const res = await axios.delete<void, AppResponse<any>>(url);
    return res;
  }

  public static async AddProductForTemplate(
    data: any
  ): Promise<AppResponse<any>> {
    try {
      const url = Util.apiAuthUrl(`add-product-for-template`);
      const res = await axios.post<void, AppResponse<any>>(url, data);
      return res;
    } catch (error) {
      console.error("Error add product:", error);
      throw error;
    }
  }
}